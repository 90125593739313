<template>
  <v-card tile width="100%">
    <v-card-text class="pa-0">
      <v-data-table
        multi-sort
        :headers="headers"
        :items="filtered"
        :search="search"
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn :small="$vuetify.breakpoint.lgAndDown" :to="{ name: 'dashboard.pages.create' }" text tile>
              <v-icon>mdi-plus</v-icon>
              <span class="ml-1">Add New Page</span>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.main_part="{ item }">
          <div style="font-size: 60%" v-html="$options.filters.truncateHtml(item.main_part, 200)"></div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn class="mr-2" small icon :to="{name: 'dashboard.pages.show', params: { id: item.id }}">
            <v-icon
              small
            >
              mdi-eye
            </v-icon>
          </v-btn>
          <v-btn class="mr-2" small icon :to="{name: 'dashboard.pages.edit', params: { id: item.id }}">
            <v-icon
              small
            >
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn class="mr-2" small icon @click="openConfirmationForDeleting(item.id)">
            <v-icon
              small
            >
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <starboxx-confirmation
      :action="{data: id, message: 'Do you really want to delete this page?', name: 'delete-page'}"/>
  </v-card>
</template>

<script>
export default {
  name: 'Subscriber',
  data () {
    return {
      loading: null,
      search: '',
      pages: [],
      id: null,
      post: {
        title: null,
        description: null,
        file: null
      },
      headers: [
        { text: 'Title', value: 'title', sortable: true },
        { text: 'Content', value: 'main_part', sortable: true },
        { text: 'Published At', value: 'created_at', sortable: true },
        { text: 'Updated At', value: 'updated_at', sortable: true },
        { text: 'Actions', align: 'center', value: 'actions', sortable: false }
      ]
    }
  },
  mounted () {
    this.loading = 'danger'
    this.getPages()
    this.$root.$on('delete-page', (id) => {
      this.deletePage(id)
    })
  },
  methods: {
    getPages () {
      this.$http.get(`${process.env.VUE_APP_API_URL}/api/pages`)
        .then(response => {
          this.pages = response.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    openConfirmationForDeleting (id) {
      this.id = id
      this.$store.dispatch('setConfirmation', true)
    },
    deletePage (id) {
      this.$http.delete(`${process.env.VUE_APP_API_URL}/api/pages/${id}`)
        .then(() => {
          this.getPages()
        })
    },
    stripHtml (html) {
      const tmp = document.createElement('div')
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ''
    }
  },
  computed: {
    filtered () {
      if (this.search) {
        return this.pages.filter(item => {
          return ((this.stripHtml(item.main_part) || '').toLowerCase().indexOf((this.search || '').toLowerCase()) > -1)
        })
      }
      return this.pages
    }
  }
}
</script>

<style scoped>

</style>
